tinymce.PluginManager.add('mergetags', (editor) => {
  // Plugin code goes here
  var secondShiftKeyPress = null;

  editor.on('keydown', (event) => {
    if (event.keyCode === 219 && event.shiftKey && !event.ctrlKey && !event.altKey) { // Left curly brace
      if (secondShiftKeyPress === 219) { // Second left curly brace

        // call merge tag dialog box
        window.templateVariable.openVariables();
      }
      secondShiftKeyPress = 219;
    }
    else {
      secondShiftKeyPress = null;
    }
  });
})

function onVariableSelect(selectedNode) {

  // find parent nodes of selected node
  let selectedNodeWithParents = window.templateVariable.findParentNodes(selectedNode);
 
  // insert unique marker for get the cursor position easily
  const UNIQUE_MARKER = "54%3!2";
  tinymce.get('templateEngineTinyEditor').insertContent(UNIQUE_MARKER);
  // insert span with unique id for set the cursor position after the mergetags insert
  tinymce.get('templateEngineTinyEditor').insertContent('<span id="spaceUniqueID54%3!2">&nbsp;</span>');

  const markerIndex = tinymce.get('templateEngineTinyEditor').getContent().indexOf(UNIQUE_MARKER);
  // get the content before cursor position except left two curly braces
  const beforeCursorContent = tinymce.get('templateEngineTinyEditor').getContent().slice(0, markerIndex - 2);

  let mergetag = `<span id="kTemplateVariable" contenteditable="false"><span contenteditable="false" style="color: #006ce7; background-color: rgba(0,108,231,.1);">{{</span>${selectedNodeWithParents}<span contenteditable="false" style="color: #006ce7; background-color: rgba(0,108,231,.1);">}}</span></span>`;
  let beforeCursorContentConcatWithMergetag = beforeCursorContent + mergetag;

  // get the content after cursor position except unique marker
  const afterCursorContent = tinymce.get('templateEngineTinyEditor').getContent().slice(markerIndex + 6);

  let contentWithMergetag = beforeCursorContentConcatWithMergetag + afterCursorContent;

  // set empty content into editor for insert with mergetags accurately, if user wants to add mergetags in the middle of text
  tinymce.get('templateEngineTinyEditor').setContent('');
  tinymce.get('templateEngineTinyEditor').insertContent(contentWithMergetag);

  let nodeSpanWithUniqueId = tinymce.get('templateEngineTinyEditor').dom.get('spaceUniqueID54%3!2');

  tinymce.get('templateEngineTinyEditor').selection.setCursorLocation(nodeSpanWithUniqueId, 0);
  
  // remove the span with unique id for next mergetags
  tinymce.get('templateEngineTinyEditor').dom.remove('spaceUniqueID54%3!2');
}

