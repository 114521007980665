tinymce.PluginManager.add('conditionalHighlight', function(editor) {
    editor.ui.registry.addButton('highlightCondition', {
        text: 'Highlight Condition',
        onAction: function() {
            let selectedText = editor.selection.getContent({ format: 'text' });
            editor.insertContent(`<span class="highlight-condition" contenteditable="false">{{${selectedText}}}</span>`);
        }
    });

    editor.on('GetContent', function(e) {
        // Convert &#47# back to /#
        e.content = e.content.replace(/&#47#(if|list)/g, '/#$1');
        // Remove the highlighting spans
        e.content = e.content.replace(/<span class="highlight-all" contenteditable="false">(.*?)<\/span>/g, '$1');
        e.content = e.content.replace(/<span class="highlight-condition" contenteditable="false">(.*?)<\/span>/g, '$1');
        e.content = e.content.replace(/<span class="highlight-tag" contenteditable="false">(.*?)<\/span>/g, '$1');

        // Remove the highlighting for ${ and }
        e.content = e.content.replace(/<span class="highlight-symbol" contenteditable="false">\$\{<\/span>/g, '${');
        e.content = e.content.replace(/<span class="highlight-symbol" contenteditable="false">}<\/span>/g, '}');
        // Restore non-editable span for variables
        e.content = e.content.replace(/<span class="highlight-variable" contenteditable="false">(.*?)<\/span>/g, '$1');

    });

    editor.on('BeforeSetContent', function(e) {
        // Convert /# to &#47#
        e.content = e.content.replace(/\/#(if|list)/g, '&#47#$1')
            .replace(/&lt;/g, '<')
            .replace(/&gt;/g, '>');
        // Handle opening tags for #if and #list
        e.content = e.content.replace(/(<#(?:if|list)\s+)([^>]*?)(>)/g,
            '<span class="highlight-all" contenteditable="false">' +
            '<span class="highlight-tag">$1</span>' +
            '<span class="highlight-condition">$2</span>' +
            '<span class="highlight-tag">$3</span>' +
            '</span>'
        );

        // Handle <#else> tag
        e.content = e.content.replace(/(<#else>)/g,
            '<span class="highlight-all" contenteditable="false">' +
            '<span class="highlight-tag">$1</span>' +
            '</span>'
        );

        // Handle closing tags for #if and #list in &lt;/#if&gt; or &lt;/#list&gt; format
        e.content = e.content.replace(/&lt;\/#(if|list)&gt;/g,
            '<span class="highlight-all" contenteditable="false">' +
            '<span class="highlight-tag">&lt;/#$1&gt;</span>' +
            '</span>'
        );

        // Handle closing tags for #if and #list in <&#47#if> or <&#47#list> format
        e.content = e.content.replace(/<&#47#(if|list)>/g,
            '<span class="highlight-all" contenteditable="false">' +
            '<span class="highlight-tag">&lt;/#$1&gt;</span>' +
            '</span>'
        );

        // Handle ${ and } with non-editable content in between
        e.content = e.content.replace(/\$\{([^}]+)\}/g,
            '<span class="highlight-symbol" contenteditable="false">${</span>' +
            '<span class="highlight-variable" contenteditable="false">$1</span>' +
            '<span class="highlight-symbol" contenteditable="false">}</span>'
        );

    });



    editor.on('init', function() {

        // editor.on('click', function(event) {
        //     let target = event.target;
        //
        //     // Check if the clicked element is the highlight condition
        //     if (target && target.classList.contains('highlight-condition')) {
        //         let parentSpan = target.closest('span[data-info]');
        //         if (parentSpan) {
        //             let dataInfo = parentSpan.getAttribute('data-info');
        //             editor.fire('highlightClick', { info: dataInfo, condition: target.innerHTML, targetElement: target });
        //         }
        //     }
        // });

        // editor.on('contextmenu', function(event) {
        //     let target = event.target;
        //
        //     if (target && target.classList.contains('highlight-variable')) {
        //         editor.fire('editorCopyText', { text: target.innerText, targetElement: target });
        //     }
        // });

        // editor.on('click', function(event) {
        //     let target = event.target;
        //
        //     if (target && target.classList.contains('highlight-condition')) {
        //         let currentCondition = target.innerText;
        //         editor.fire('highlightClick', { condition: currentCondition, targetElement: target });
        //     }
        // });

    });
});